import React from "react";
import style from "./About.module.css";
const About = () => {
  return (
    <>
      <div className={style.about_bg_container}>
        <h1>
          Transformative Solutions for <br />
          Educational Excellence
        </h1>
        <p>
          We are a leading software solutions company where innovation meets
          education and is dedicated to revolutionizing the educational
          landscape through cutting-edge technologies tailored for academic
          institutions.
        </p>
      </div>

      <div className={style.mission_container}>
        <h1>Our Mission</h1>
        <p>
          At Aspiron Technologies, our mission is to empower educational
          institutions with transformative software solutions that enhance
          efficiency, elevate teaching and learning experiences, and ultimately
          contribute to the advancement of education on a global scale.
        </p>
      </div>
      <div className={style.choose_bg_cnt}>
        <h1>Why Choose Aspiron Technologies?</h1>
        <div className={`${style.choose_card_cnt} ${style.card_1}`}>
          <h1>Educational Expertise</h1>
          <p>
            Our team consists of experts with a deep understanding of the unique
            challenges faced by educational institutions. We tailor our
            solutions to address the specific needs of the academic environment.
          </p>
        </div>
        <div className={`${style.choose_card_cnt} ${style.card_2}`}>
          <h1>Innovation at the Core</h1>
          <p>
            We thrive on innovation, constantly exploring new technologies and
            methodologies to stay at the forefront of educational software
            development. Our solutions reflect the latest advancements in the
            field.
          </p>
        </div>
        <div className={`${style.choose_card_cnt} ${style.card_3}`}>
          <h1>Comprehensive Solutions</h1>
          <p>
            From administrative tools that streamline processes to interactive
            learning platforms, we offer a comprehensive suite of solutions
            designed to meet the diverse needs of educational institutions.
          </p>
        </div>
        <div className={`${style.choose_card_cnt} ${style.card_4}`}>
          <h1>User-Centric Approach</h1>
          <p>
            Our software solutions are built with the end-users in mind. We
            prioritize user experience, ensuring that our products are
            intuitive, user-friendly, and seamlessly integrate into existing
            workflows.
          </p>
        </div>
        <div className={`${style.choose_card_cnt} ${style.card_5}`}>
          <h1>Scalability and Flexibility</h1>
          <p>
            Recognizing that educational institutions vary in size and
            structure, our solutions are scalable and flexible. Whether you're a
            small school or a large university, we have solutions that can adapt
            to your unique requirements.
          </p>
        </div>
      </div>

      <div className={style.commitment_bg_cnt}>
        <h1>Our Commitment to Excellence</h1>
        <div className={`${style.commitment_card_cnt} ${style.card_1}`}>
          <h1>Continuous Improvement</h1>
          <p>
            We believe in the power of continuous improvement. Our software
            solutions are designed to evolve alongside the ever-changing
            landscape of education, ensuring that your institution stays ahead
            of the curve.
          </p>
        </div>
        <div className={`${style.commitment_card_cnt} ${style.card_2}`}>
          <h1>Collaboration and Partnership</h1>
          <p>
            We see our clients as partners in the journey towards educational
            excellence. Through collaboration, we work together to identify
            challenges, devise solutions, and achieve shared goals.
          </p>
        </div>
        <div className={`${style.commitment_card_cnt} ${style.card_3}`}>
          <h1>Data Security and Compliance</h1>
          <p>
            The security of your institution's data is our top priority. Our
            solutions adhere to the highest standards of data security and
            comply with relevant regulations to ensure the confidentiality and
            integrity of your information.
          </p>
        </div>
      </div>

      <div className={style.join_bg_cnt}>
        <h1>Join Us in Shaping the Future of Education</h1>
        <p>
          At Aspiron Technologies, we are not just a software solutions
          provider; we are catalysts for positive change in the educational
          sphere. Join us in shaping the future of education through innovative,
          user-centric, and transformative solutions.
        </p>
      </div>
    </>
  );
};

export default About;
