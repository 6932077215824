import React from "react";
import style from "./Ewallet.module.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Lottie from "lottie-react";
import digi1 from "../../assets/digi1.1.jpg";
import digi2 from "../../assets/digi1.2.jpg";
import digi3 from "../../assets/digi1.3.jpg";
import digi4 from "../../assets/digi1.4.jpg";
import digi5 from "../../assets/digi1.5.jpg";
const Ewallet = () => {
  return (
    <div>
      <div className={style.obe_bg_cnt}>
        {/* <Lottie animationData={obe} loop={true} /> */}
        <div className={style.obe_cnt}>
          <h1>Digital Wallet</h1>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <h1 className={style.obe_heading}>Key Features</h1>

          <div className={style.obe_card_cnt}>
            <img src={digi1} alt="Exam" className={style.obe_card_img} />
            <div className={style.obe_card}>
              <h1>Contactless Transactions</h1>
              <p>
                Simplify on-campus purchases with our digital wallet's
                contactless payment feature. From cafeteria meals to bookstore
                acquisitions, students can complete transactions effortlessly
                with a tap or scan.
              </p>{" "}
            </div>
          </div>
          <div className={style.obe_card_cnt}>
            <img src={digi2} alt="Exam" className={style.obe_card_img} />
            <div className={style.obe_card}>
              <h1>Student ID Integration</h1>
              <p>
                Seamlessly merge student IDs with our digital wallet,
                eliminating the need for multiple cards. This integration
                ensures a unified experience for users, enhancing accessibility
                and reducing the risk of lost cards.
              </p>
            </div>
          </div>

          <div className={style.obe_card_cnt}>
            <img src={digi3} alt="Exam" className={style.obe_card_img} />
            <div className={style.obe_card}>
              <h1>Automated Allowances</h1>
              <p>
                Empower students by automating allowances and stipends directly
                through the digital wallet. This feature streamlines financial
                management, allowing students to focus on their studies rather
                than administrative tasks.
              </p>
            </div>
          </div>

          <div className={style.obe_card_cnt}>
            <img src={digi4} alt="Exam" className={style.obe_card_img} />
            <div className={style.obe_card}>
              <h1>Security and Compliance</h1>
              <p>
                Prioritize security with our robust features, meeting the
                compliance standards necessary for educational institutions.
                Enjoy peace of mind knowing that all transactions are secure and
                protected.
              </p>
            </div>
          </div>

          <div className={style.obe_card_cnt}>
            <img src={digi5} alt="Exam" className={style.obe_card_img} />
            <div className={style.obe_card}>
              <h1>Real-Time Balance Monitoring</h1>
              <p>
                Enable users to monitor their spending and account balances in
                real-time. This feature promotes financial responsibility among
                students while providing them with insights into their financial
                health.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-flex flex-column d-md-flex flex-md-row justify-content-start">
        <div>
          <h1 className={style.obe_heading}>Benefits</h1>

          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Streamlined Transactions</h1>
              <p>
                Say goodbye to the hassle of handling cash on campus. Our
                digital wallet consolidates all transactions into one secure
                platform, making financial interactions more efficient and
                transparent.
              </p>
            </div>
          </div>

          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Enhanced Student Life</h1>
              <p>
                Elevate the campus experience by providing students with a
                convenient tool for managing their finances. Our digital wallet
                contributes to a seamless and enriched campus life.
              </p>
            </div>
          </div>

          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1> Administrative Efficiency</h1>
              <p>
                Simplify financial administration with centralized control and
                reporting tools. Our digital wallet streamlines processes for
                administrators and finance departments, reducing manual effort
                and paperwork.
              </p>
            </div>
          </div>

          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Data-Driven Insights</h1>
              <p>
                Access valuable data and insights into spending patterns,
                helping institutions make informed financial decisions. Our
                analytics tools provide a comprehensive view of financial
                activities on campus.
              </p>
            </div>
          </div>
        </div>
        <div>
          <h1 className={style.obe_heading}>Why Aspiron's Digital Wallet?</h1>
          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Tailored for Education</h1>
              <p>
                Our digital wallet software is specifically designed to meet the
                unique financial needs of educational institutions, ensuring a
                seamless fit into the campus environment.
              </p>
            </div>
          </div>

          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>User-Friendly Interface</h1>
              <p>
                Enjoy an intuitive and user-friendly interface for both
                administrators and users, enhancing overall usability and
                adoption.
              </p>
            </div>
          </div>

          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Scalability</h1>
              <p>
                Grow your digital ecosystem with confidence. Our solution is
                scalable to meet the evolving financial needs of educational
                institutions of all sizes.
              </p>
            </div>
          </div>

          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Expert Support</h1>
              <p>
                Rely on our dedicated support team to guide you through
                implementation and address any queries promptly, ensuring a
                smooth transition to a cashless campus. Elevate your campus
                experience with Aspiron's Digital Wallet Software. Contact us
                today for a personalized demonstration and embark on a journey
                towards a cashless, efficient, and secure campus financial
                ecosystem.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={style.obe_card_cnt_two}></div>
    </div>
  );
};

export default Ewallet;
