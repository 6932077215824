// import axios from 'axios';
// import toastr from 'toastr';
import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import style from "./Careers.module.css";
import "../../node_modules/slick-carousel/slick/slick.css";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Puff } from "react-loader-spinner";

const Careers = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [data, setData] = useState({});

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [resume, setResume] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const form = useRef();
  const sendDetails = async (e, data) => {
    e.preventDefault();
    setIsLoading(true);
    // emailjs
    //   .sendForm(
    //     "service_tg6ym9p",
    //     "template_jb3stbm",
    //     form.current,
    //     "BPnDYCF1NQfFe7gA4"
    //   )
    //   .then(
    //     (result) => {
    //       console.log("result====>", result.text);
    //       if (result.text === "OK") {
    //         alert("Details sent successfully");
    //         form.current.reset();
    //       }
    //     },
    //     (error) => {
    //       console.log(error.text);
    //       alert("Something went wrong");
    //     }
    //   );
    const finalData = {
      name: name,
      email: email,
      phoneNumber: mobile,
      filePath: resume.name,
      jobTitle: data.title,
      jobRole: data.role_type,
      jobLocation: data.location,
      jobDescription: data.description,
      jobExperience: data.experience,
    };
    console.log("finalData", JSON.stringify(finalData));
    const formData = new FormData();
    function jsonBlob(obj) {
      return new Blob([JSON.stringify(obj)], {
        type: "application/json",
      });
    }
    formData.append("AspironJob", jsonBlob(finalData));
    formData.append("AspironJobFile", resume);
    // const config = {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // };
    try {
      const res = await axios.post(
        "https://app.adhikrit.com:8443/crm-backend-adhikrit/api/v1/Aspiron/Job/Applied",
        // "http://192.168.0.126:9090/crm-backend-adhikrit/api/v1/Aspiron/Job/Applied",
        formData
      );
      console.log("res", res);
      if (res.status === 200) {
        setIsLoading(false);
        setName("");
        setEmail("");
        setMobile("");
        setResume("");
        handleClose();
        alert("Details sent successfully");
      }
    } catch (e) {
      console.error("error", e);
    }
  };

  const [jobs, setJobs] = useState([]);
  function getJobs() {
    axios
      .get(
        "https://app.adhikrit.com:8443/crm-backend-adhikrit/api/v1/available/jobs",
        // "http://192.168.0.126:9090/crm-backend-adhikrit/api/v1/available/jobs"
      )
      .then((res) => {
        console.log("jobPosts=====>", res);
        if(res.data){
          setJobs(res.data);
        }else{
          alert("No jobs available");
        }
        
      })
      .catch((err) => {
        console.error("error",err)
      });
  }

  useEffect(() => {
    getJobs();
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    // slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if (isLoading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          position: "absolute",
          bottom: "0",
          right: "0",
          left: "0",
          zIndex: "1000000",
        }}
      >
        <Puff
          visible={true}
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="puff-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }

  return (
    <div>
      <section
        id="Header"
        className="text-dark p-md-5 p-lg-0 pt-lg-5 text-center text-sm-start"
        style={{
          marginBottom: "1rem",
          backgroundColor: "rgb(255, 255, 255)",
          borderRadius: "0px 0px 50px 50px",
        }}
      >
        <div className={style.career_intro_card}>
          <h1>Be a part of our success story!</h1>
          <p>
            Join our growing and energetic team of talent, we always look for
            people who add positivity at our workspace and strive to achieve
            organizational goals. Adiverse Tech has endless opportunities in
            shaping your career, be it an entry level job or senior level
            position. Let’s work and grow together.
          </p>
        </div>
      </section>

      <div className="container">
        <div className="row md-12">
          {jobs && jobs.length > 0 ? (
            <>
              {jobs.map((job, index) => (
                <div key={index} className="col-12 col-md-3 col-lg-4">
                  <h1
                    style={{
                      textAlign: "center",
                      marginTop: "5rem",
                      marginBottom: "35px",
                      color: "#02334d",
                    }}
                  >
                    Currently Hiring
                  </h1>
                  <div
                    className="card w-40 p-2 mb-3 shadow blockGradiendBg careerBlock"
                    style={{ border: "none" }}
                  >
                    <div className="card-body text-center">
                      <h5 className="card-title fw-bold mb-1">{job.title}</h5>
                      <p className="card-text mb-4">
                        <small>
                          <span>Preferred experience: {job.experience}</span>
                          <br />
                          <span>Location: {job.location}</span>
                          <br />
                          <span>Role type: {job.role}</span>
                        </small>
                      </p>
                      <form>
                        <button
                          type="button"
                          className="btn btn-sm w-100 bg-white fw-bold border-none rounded-1"
                          style={{ color: "#fd5825" }}
                          onClick={() => {
                            handleShow();
                            setData(job);
                          }}
                        >
                          <small>APPLY NOW</small>
                        </button>
                        <Modal show={show} onHide={handleClose} size="lg">
                          <Modal.Header closeButton>
                            <Modal.Title style={{ color: "#000000" }}>
                              Apply Job
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="d-md-flex justify-content-around align-items-start">
                              <div style={{ color: "#000000", width: "50%" }}>
                                <div className="mb-5">
                                  <h1>{data.title}</h1>
                                  <p>{data.description}</p>
                                  <p>
                                    Preferred experience:{" "}
                                    <span>{data.experience}</span>
                                  </p>
                                  <p>
                                    Location: <span>{data.location}</span>
                                  </p>
                                  <p>
                                    Role type: <span>{data.role}</span>
                                  </p>
                                </div>
                              </div>
                              <form
                                ref={form}
                                onSubmit={(e) => sendDetails(e, data)}
                                style={{ color: "#000000" }}
                              >
                                <div className={style.form_group}>
                                  <label>Name</label>
                                  <input
                                    value={name}
                                    name="name"
                                    type="text"
                                    placeholder="Name"
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                                <div className={style.form_group}>
                                  <label>Email</label>
                                  <input
                                    name="email"
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                                <div className={style.form_group}>
                                  <label>Mobile Number</label>
                                  <input
                                    name="mobile"
                                    type="text"
                                    placeholder="Mobile Number"
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                  />
                                </div>
                                <div className={style.form_group}>
                                  <label>Upload your Resume</label>
                                  <input
                                    onChange={(e) =>
                                      setResume(e.target.files[0])
                                    }
                                    name="resume"
                                    type="file"
                                    placeholder="Resume"
                                  />
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-primary mt-3 w-100"
                                >
                                  Send Your Details
                                </button>
                              </form>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            {/* <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button> */}
                          </Modal.Footer>
                        </Modal>
                      </form>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <h1
              style={{
                textAlign: "center",
                marginTop: "5rem",
                marginBottom: "35px",
                color: "#02334d",
              }}
            >
              Currently No Jobs available
            </h1>
          )}
        </div>
      </div>

      <div
        className="container-fluid p-0"
        style={{ marginTop: "1%", textAlign: "left" }}
      >
        <section
          id="testimonials"
          style={{
            backgroundColor: "rgb(253 88 37 / 10%)",
            padding: "10px",
            margin: "0px",
          }}
        >
          <header className="section-header text-center">
            <h2 style={{ color: "rgb(0, 212, 199)", fontSize: "13px" }}>
              Testimonials
            </h2>
            <p style={{ fontSize: "25px", color: "#02334d" }}>
              <strong>What our employees are saying</strong>
            </p>
          </header>
          <div
            id="testimonial-carousel"
            className="carouselwidth carousel slide mx-auto"
            data-bs-ride="carousel"
            data-bs-interval="3800"
            style={{ maxWidth: "900px" }}
          >
            <div className={style.slider_cnt}>
              <Slider {...settings}>
                <div>
                  <div
                    className="card p-4 pb-3 d-flex justify-content-center"
                    style={{ borderRadius: "20px" }}
                  >
                    <div className="card-body">
                      <p
                        className="card-text pb-5"
                        style={{
                          borderBottom:
                            "0.7px solid rgba(228, 226, 226, 0.824)",
                        }}
                      >
                        “Adiverse is a great place to work with a happy
                        environment. I always wanted to be part of a friendly
                        and sportive work group I’m Glad I am part of
                        Adiversetech.”
                      </p>
                      <div className="row d-flex align-items-center">
                        <div className="col-md-5 mt-3">
                          <h5>Sireesha</h5>
                          <p>Software Developer</p>
                        </div>
                        <div className="col-md-5 d-flex justify-content-end"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    className="card p-4 pb-3 d-flex justify-content-center"
                    style={{ borderRadius: "20px" }}
                  >
                    <div className="card-body">
                      <p
                        className="card-text pb-5"
                        style={{
                          borderBottom:
                            "0.7px solid rgba(228, 226, 226, 0.824)",
                        }}
                      >
                        “Adiversetech has provided me with a platform to learn
                        and constantly evaluate my progress. They helped me
                        build a vision of what I would like to achieve in coming
                        few years.”
                      </p>
                      <div className="row d-flex align-items-center">
                        <div className="col-md-5 mt-3">
                          <h5>Pavan Kumar</h5>
                          <p>Software Developer</p>
                        </div>
                        <div className="col-md-5 d-flex justify-content-end"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="card p-md-4 pb-3 d-flex justify-content-center"
                    style={{ borderRadius: "20px" }}
                  >
                    <div className="card-body">
                      <p
                        className="card-text pb-5"
                        style={{
                          borderBottom:
                            "0.7px solid rgba(228, 226, 226, 0.824)",
                        }}
                      >
                        “I’m glad to work with such a challenging work
                        environment where the focus is to solve problems through
                        team cooperation and moving towards a common vision.”
                      </p>
                      <div className="row d-flex align-items-center">
                        <div className="col-md-5 mt-3">
                          <h5>Pranay</h5>
                          <p>DevOps Engineer</p>
                        </div>
                        <div className="col-md-5 d-flex justify-content-end"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          <div className="d-flex justify-content-center pt-3">
            <button
              type="button"
              data-bs-target="#testimonial-carousel"
              data-bs-slide="prev"
              style={{ background: "none", border: "none" }}
            ></button>
            <button
              type="button"
              data-bs-target="#testimonial-carousel"
              data-bs-slide="next"
              style={{ background: "none", border: "none" }}
            ></button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Careers;
