import React from "react";
import style from "./Nirf.module.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Lottie from "lottie-react";
import obe from "../../assets/obe.json";
import obe1 from "../../assets/nirf1.jpg";
import obe2 from "../../assets/nirf2.jpg";
import obe3 from "../../assets/nirf3.jpg";
import obe4 from "../../assets/nirf4.jpg";

const Nirf = () => {
  return (
    <div>
      <div className={style.obe_bg_cnt}>
        {/* <Lottie animationData={obe} loop={true} /> */}
        <div className={style.obe_cnt}>
          {/* <h1>Outcome Based<h1 className="d-md-none d-sm-block">Education</h1></h1> */}
        </div>
      </div>
      <div className="container">
        <h1 className={style.obe_heading}>Why NIRF Recognition Matters</h1>

        <div className={style.obe_card_cnt}>
          <img src={obe1} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>National Visibility</h1>
            <p>
              NIRF rankings provide national visibility, positioning your
              institution prominently in the higher education landscape
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt}>
          <img src={obe2} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>Credibility and Trust</h1>
            <p>
              NIRF recognition enhances your institution's credibility and
              builds trust among students, parents, and stakeholders
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt}>
          <img src={obe3} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>Benchmark for Excellence</h1>
            <p>
              The framework sets a benchmark for excellence, encouraging
              institutions to continuously improve in various aspects, including
              academics, research, and infrastructure.
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt}>
          <img src={obe4} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>Competitive Edge</h1>
            <p>
              Institutions ranked by NIRF gain a competitive edge in attracting
              top-tier students, faculty, and collaborations.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <h1 className={style.obe_heading}>
          How Aspiron Technologies Facilitates Your NIRF Journey
        </h1>
        <div className={style.obe_card_cnt2}>
          <div className={style.obe_card}>
            <h1>NIRF Consultation Services</h1>
            <p>
              Expert guidance on understanding the NIRF framework, criteria, and
              parameters to align your institution effectively.
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt2}>
          <div className={style.obe_card}>
            <h1>Data Compilation and Analysis</h1>
            <p>
              Assistance in compiling and analyzing the data required for NIRF
              submission, ensuring accuracy and completeness.
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt2}>
          <div className={style.obe_card}>
            <h1>Strategic Planning for Improvement</h1>
            <p>
              Collaborative planning sessions to identify areas for improvement
              and develop strategies to enhance your institution's performance
              in NIRF parameters
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt2}>
          <div className={style.obe_card}>
            <h1>Documentation Support</h1>
            <p>
              Comprehensive support in preparing the necessary documentation to
              meet NIRF standards and showcase your institution's strengths
            </p>
          </div>
        </div>
      </div>

      {/* <h1 className={style.obe_heading}>Why Aspiron's OBE Software?</h1>

    <div className={style.obe_card_cnt}>
      <div className={style.obe_card}>
        <h1>Educational Expertise</h1>
        <p>
          PrBenefit from our deep understanding of educational processes. Our
          software is developed with the unique needs of educational
          institutions in mind.
        </p>
      </div>
    </div>

    
    <div className={style.obe_card_cnt}>
      <div className={style.obe_card}>
        <h1>Adaptability</h1>
        <p>
          Customize our OBE software to fit the specific requirements of your
          institution. Ensure seamless integration with your existing
          educational framework.
        </p>
      </div>
    </div>
    <div className={style.obe_card_cnt}>
      <div className={style.obe_card}>
        <h1>Scalability</h1>
        <p>
          Grow confidently with our scalable solution. Whether you're a
          school, college, or university, our OBE software evolves with the
          changing needs of educational institutions.
        </p>
      </div>
    </div>
    <div className={style.obe_card_cnt}>
      <div className={style.obe_card}>
        <h1>Dedicated Support</h1>
        <p>
          Rely on our expert support team to guide you through implementation
          and address any queries promptly, ensuring a smooth transition to
          OBE practices. Discover the transformative power of [Your Company
          Name]'s Outcome-Based Education software. Contact us today for a
          personalized demonstration and embark on a journey toward a more
          outcomes-focused, student-centric, and quality-driven education
          system.
        </p>
      </div>
    </div> */}
    </div>
  );
};

export default Nirf;
