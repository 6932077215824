import React from "react";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../assets/Aspiron-Logo.jpg";
// import style from "./Navbar.module.css";
import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";

const Menubar = () => {
  const location = useLocation();
  return (
    <>
      <nav>
        <div className="wrapper">
          <div className="logo">
            <div className="logo_img">
              <a href="/">
                <img src={logo} alt="Logo" />
              </a>
            </div>
          </div>
          <input type="radio" name="slider" id="menu-btn" />
          <input type="radio" name="slider" id="close-btn" />
          <ul className="nav-links">
            <label for="close-btn" className="btn close-btn">
              <i className="fas fa-times text-dark"></i>
            </label>
            <li>
              <a
                className={`${location.pathname === "/" ? "active" : ""}`}
                href="/"
              >
                Home
              </a>
            </li>
            <li>
              <a
                className={`${location.pathname === "/about" ? "active" : ""}`}
                href="/about"
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#"
                className={
                  location.pathname === "/erp" ||
                  location.pathname === "/exam" ||
                  location.pathname === "/wallet" ||
                  location.pathname === "/naac" ||
                  location.pathname === "/obe" ||
                  location.pathname === "/nirf" ||
                  location.pathname === "/iqac" ||
                  location.pathname === "/smart"
                    ? "active"
                    : "desktop-item"
                }
              >
                Our Products
              </a>
              <input type="checkbox" id="showDrop" />
              <label for="showDrop" className="mobile-item">
                Our Products
              </label>
              <ul className="drop-menu">
                <li>
                  <a
                    className={`${
                      location.pathname === "/erp" ? "active" : ""
                    }`}
                    style={{ whiteSpace: "nowrap" }}
                    href="/erp"
                  >
                    ERP
                  </a>
                </li>

                <li>
                  <a
                    className={`${
                      location.pathname === "/exam" ? "active" : ""
                    }`}
                    style={{ whiteSpace: "nowrap" }}
                    href="/exam"
                  >
                    Digital Evaluation
                  </a>
                </li>

                <li>
                  <a
                    className={`${
                      location.pathname === "/wallet" ? "active" : ""
                    }`}
                    style={{ whiteSpace: "nowrap" }}
                    href="/wallet"
                  >
                    Digital wallet
                  </a>
                </li>
                <li>
                  <a
                    className={`${
                      location.pathname === "/naac" ? "active" : ""
                    }`}
                    style={{ whiteSpace: "nowrap" }}
                    href="/naac"
                  >
                    NAAC
                  </a>
                </li>
                <li>
                  <a
                    className={`${
                      location.pathname === "/obe" ? "active" : ""
                    }`}
                    style={{ whiteSpace: "nowrap" }}
                    href="/obe"
                  >
                    OBE
                  </a>
                </li>
                <li>
                  <a
                    className={`${
                      location.pathname === "/nirf" ? "active" : ""
                    }`}
                    style={{ whiteSpace: "nowrap" }}
                    href="/nirf"
                  >
                    NIRF
                  </a>
                </li>
                <li>
                  <a
                    className={`${
                      location.pathname === "/iqac" ? "active" : ""
                    }`}
                    style={{ whiteSpace: "nowrap" }}
                    href="/iqac"
                  >
                    IQAC Dashboard
                  </a>
                </li>
                <li>
                  <a
                    className={`${
                      location.pathname === "/smart" ? "active" : ""
                    }`}
                    style={{ whiteSpace: "nowrap" }}
                    href="/smart"
                  >
                    Face Recognition Software
                  </a>
                </li>
              </ul>
            </li>
            {/* <li>
              <a href="#" className="desktop-item">
                Products
              </a>
              <input type="checkbox" id="showMega" />
              <label for="showMega" className="mobile-item">
                Products
              </label>
              <div className="mega-box">
                <div className="content">
                  <div className="row">
                    <Link to="/erp">
                      <header>ERP</header>
                    </Link>

                    <ul className="mega-links">
                      <li>
                        <a href="#">Student Information</a>
                      </li>
                      <li>
                        <a href="#">Course and Curriculum</a>
                      </li>
                      <li>
                        <a href="#">Financial Management</a>
                      </li>
                      <li>
                        <a href="#">Human Resource Management</a>
                      </li>
                      <li>
                        <a href="#">Library and Resource Tracking</a>
                      </li>
                    </ul>
                  </div>
                  <div className="row">
                    <Link to="/exam">
                      <header>Digital Evaluation</header>
                    </Link>

                    <ul className="mega-links">
                      <li>
                        <a href="#">Scheduling and Timetabling</a>
                      </li>
                      <li>
                        <a href="#">Automated Grading</a>
                      </li>
                      <li>
                        <a href="#">Result Processing</a>
                      </li>
                      <li>
                        <a href="#">Secure Exam Administration</a>
                      </li>
                      <li>
                        <a href="#">Communication Hub</a>
                      </li>
                    </ul>
                  </div>
                  <div className="row">
                    <header>OBE</header>
                    <ul className="mega-links">
                      <li>
                        <a href="#">Curriculum Alignment</a>
                      </li>
                      <li>
                        <a href="#">Learning Outcome Mapping</a>
                      </li>
                      <li>
                        <a href="#">Assessment Tools</a>
                      </li>
                      <li>
                        <a href="#">Analytics and Reporting</a>
                      </li>
                      <li>
                        <a href="#">Student-Centric Approach</a>
                      </li>
                    </ul>
                  </div>
                  <div className="row">
                    <header>Digi-wallet</header>
                    <ul className="mega-links">
                      <li>
                        <a href="#">Contactless Transactions</a>
                      </li>
                      <li>
                        <a href="#">Student ID Integration:</a>
                      </li>
                      <li>
                        <a href="#">Automated Allowances</a>
                      </li>
                      <li>
                        <a href="#">Security and Compliance</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li> */}
            <li>
              <a
                className={`${
                  location.pathname === "/careers" ? "active" : ""
                }`}
                href="/careers"
              >
                Careers
              </a>
            </li>
          </ul>
          <label for="menu-btn" className="btn menu-btn">
            <i className="fas fa-bars"></i>
          </label>
        </div>
      </nav>

      {/* <div className="body-text">
        <div className="title">Responsive Dropdown and Mega Menu</div>
        <div className="sub-title">using only HTML & CSS</div>
      </div> */}
    </>
  );
};

export default Menubar;
