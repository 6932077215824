import React, { useEffect } from "react";
import Lottie from "lottie-react";
import student from "../../assets/student_giff.json";
import style from "./Erp.module.css";
import Course from "../../assets/Course.json";
import finantial from "../../assets/finantial.json";
import human from "../../assets/human.json";
import library from "../../assets/library.json";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Erp = () => {
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 250, // offset (in px) from the original trigger point
      delay: 100, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
  return (
    <>
      <div class={style.container}>
        <h1 contenteditable>
          <h1 contenteditable>Enterprise Resource</h1>
          <h1 contenteditable>Planning</h1>
        </h1>

        <p>Welcome to the future of academic management</p>
        <p>
          with Aspiron's's Enterprise Resource Planning (ERP) software designed
          exclusively for educational institutions.{" "}
        </p>
      </div>

      <div className="container">
        <div className="row">
          <div
            className={`col-12 d-sm-flex flex-sm-column flex-md-row justify-content-center align-items-center mt-5 rounded  ${style.erp_card_cnt}`}
          >
            <div className={`order-sm-0 ${style.erp_anime}`}>
              <Lottie animationData={student} loop={true} />
            </div>
            <div className={`order-sm-1 ${style.erp_card}`}>
              <h1>Student Information Management</h1>
              <p>
                Effortlessly manage student data, attendance records, and
                academic progress. Our ERP provides a centralized database for
                comprehensive student information, streamlining administrative
                tasks.
              </p>
            </div>
          </div>
          <div
            className={`col-12 mt-5 border border-1 rounded shadow d-sm-flex flex-sm-column flex-md-row justify-content-center align-items-center ${style.erp_card_cnt}`}
            style={{ backgroundColor: "#fff", paddingTop: "10px" }}
          >
            <div className={`d-md-none d-sm-block ${style.erp_anime}`}>
              <Lottie animationData={Course} loop={true} />
            </div>
            <div className={`order-sm-1 order-md-0 ${style.erp_card}`}>
              <h1>Course and Curriculum Management</h1>
              <p>
                Tailor courses, manage curriculum structures, and track academic
                programs seamlessly. Our ERP empowers institutions to adapt and
                evolve their offerings to meet changing educational demands. .
              </p>
            </div>
            <div className={`d-none d-md-block ${style.erp_anime}`}>
              <Lottie animationData={Course} loop={true} />
            </div>
          </div>
          <div
            className={` col-12 mt-5 border border-1 rounded d-sm-flex flex-sm-column flex-md-row justify-content-center align-items-center ${style.erp_card_cnt}`}
            style={{ backgroundColor: "lightblue", paddingTop: "10px" }}
          >
            <div className={`order-sm-0 ${style.erp_anime}`}>
              <Lottie animationData={finantial} loop={true} />
            </div>
            <div className={`order-sm-1 ${style.erp_card}`}>
              <h1>Financial Management</h1>
              <p>
                Optimize budgeting, track expenses, and ensure financial
                transparency. Our ERP integrates robust financial tools,
                facilitating efficient resource allocation and financial
                decision-making.
              </p>
            </div>
          </div>
          <div
            className={`col-12 mt-5 border border-1 rounded shadow d-sm-flex flex-sm-column flex-md-row justify-content-center align-items-center ${style.erp_card_cnt}`}
            style={{ backgroundColor: "#fff", paddingTop: "10px" }}
          >
            <div className={`d-md-none d-sm-block ${style.erp_anime}`}>
              <Lottie animationData={human} loop={true} />
            </div>
            <div className={`order-sm-3 order-md-0 ${style.erp_card}`}>
              <h1>Human Resource Management</h1>
              <p>
                Streamline HR processes, from staff recruitment to payroll
                management. Our ERP enhances workforce efficiency, ensuring that
                educational institutions can focus on delivering high-quality
                education.
              </p>
            </div>
            <div className={`d-none d-md-block ${style.erp_anime}`}>
              <Lottie animationData={human} loop={true} />
            </div>
          </div>
          <div
            className={`col-12 mt-5 border border-1 rounded d-sm-flex flex-sm-column flex-md-row justify-content-center align-items-center ${style.erp_card_cnt}`}
            style={{ backgroundColor: "lightgray", paddingTop: "10px" }}
          >
            <div className={`order-sm-0 ${style.erp_anime}`}>
              <Lottie animationData={library} loop={true} />
            </div>
            <div className={`order-sm-1 ${style.erp_card}`}>
              <h1>Library and Resource Tracking</h1>
              <p>
                Manage library resources, track inventory, and streamline
                resource allocation. Our ERP software ensures that educational
                materials are readily available, enhancing the learning
                experience for students and faculty.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Erp;
