import React from "react";
import style from "./Exam.module.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Lottie from "lottie-react";
import exam1 from "../../assets/exam1.1.jpg";
import exam2 from "../../assets/exam1.2.jpg";
import exam3 from "../../assets/exam1.3.jpg";
import exam4 from "../../assets/exam1.4.jpg";
import exam5 from "../../assets/exam1.5.jpg";
import benifits from "../../assets/benifits.jpg";
const Exam = () => {
  return (
    <div>
      <div className={style.obe_bg_cnt}>
        <div className={style.obe_cnt}>
          <h1>Digital Evaluation</h1>
        </div>
      </div>
      <div className={style.bottom_cnt}>
        <div className="container w-100">
          <div className="row">
            <h1 className={style.obe_heading}>Key Features</h1>
            <div className={`col-12 ${style.obe_card_cnt}`}>
              <img src={exam1} alt="Exam" className={style.obe_card_img} />
              <div className={style.obe_card}>
                <h1>Scheduling and Timetabling</h1>
                <p>
                  Streamline the complex task of exam scheduling and
                  timetabling. Our software allows for efficient allocation of
                  resources, examination halls, and invigilators, minimizing
                  conflicts and ensuring a smooth exam period.
                </p>
              </div>
            </div>
            <div className={`col-12 ${style.obe_card_cnt}`}>
              <img src={exam2} alt="Exam" className={style.obe_card_img} />
              <div className={style.obe_card}>
                <h1>Automated Grading</h1>
                <p>
                  Eliminate manual grading processes with our automated grading
                  system. Improve accuracy, reduce errors, and speed up the
                  release of results, providing timely feedback to students and
                  faculty.
                </p>
              </div>
            </div>
            <div className={`col-12 ${style.obe_card_cnt}`}>
              <img src={exam3} alt="Exam" className={style.obe_card_img} />
              <div className={style.obe_card}>
                <h1>Result Processing</h1>
                <p>
                  Simplify result processing with our intuitive system. Generate
                  reports, transcripts, and grade sheets effortlessly, ensuring
                  accurate and timely distribution of examination outcomes to
                  students and relevant stakeholders.
                </p>
              </div>
            </div>
            <div className={`col-12 ${style.obe_card_cnt}`}>
              <img src={exam4} alt="Exam" className={style.obe_card_img} />
              <div className={style.obe_card}>
                <h1>Secure Exam Administration</h1>
                <p>
                  Prioritize the security of examination processes with our
                  robust features. Prevent malpractices, maintain the integrity
                  of exams, and ensure a fair and secure environment for all
                  students.
                </p>
              </div>
            </div>
            <div className={`col-12 ${style.obe_card_cnt}`}>
              <img src={exam5} alt="Exam" className={style.obe_card_img} />
              <div className={style.obe_card}>
                <h1>Communication Hub</h1>
                <p>
                  Facilitate seamless communication between exam cells, faculty,
                  and students. Our software serves as a centralized hub for
                  announcements, updates, and information related to
                  examinations, enhancing transparency and coordination.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container d-flex flex-column d-md-flex flex-md-row justify-content-start w-100">
          <div className="flex-grow-1">
            <h1 className={style.obe_heading}>Benefits</h1>
            <div className={style.obe_card_cnt2}>
              <div className={`w-100 ${style.obe_card}`}>
                <h1> Efficiency and Time Savings</h1>
                <p>
                  Save valuable time and resources with our automated processes.
                  From scheduling to grading, our software streamlines tasks,
                  allowing exam cell staff to focus on strategic initiatives.
                </p>
              </div>
            </div>

            <div className={style.obe_card_cnt2}>
              <div className={style.obe_card}>
                <h1>Transparency and Accountability</h1>
                <p>
                  Enhance transparency in the examination process. Our software
                  provides real-time visibility into schedules, results, and
                  communications, promoting accountability and trust among
                  stakeholders.
                </p>
              </div>
            </div>

            <div className={style.obe_card_cnt2}>
              <div className={style.obe_card}>
                <h1>Reduced Administrative Burden</h1>
                <p>
                  Minimize the administrative burden associated with exam
                  management. Our software automates routine tasks, reducing
                  paperwork and manual effort for exam cell administrators.
                </p>
              </div>
            </div>

            <div className={style.obe_card_cnt2}>
              <div className={style.obe_card}>
                <h1>Improved Student Experience:</h1>
                <p>
                  Provide students with a stress-free examination experience.
                  Our user-friendly interface and clear communication channels
                  contribute to a positive and organized exam environment.
                </p>
              </div>
            </div>
          </div>
          <div className="flex-grow-1">
            <h1 className={style.obe_heading}>
              Why Aspiron's Exam Cell Software?
            </h1>

            <div className={style.obe_card_cnt2}>
              <div className={style.obe_card}>
                <h1>Specialized Expertise</h1>
                <p>
                  With a deep understanding of educational processes, our
                  software is tailored to meet the unique needs of exam cells
                  within educational institutions.
                </p>
              </div>
            </div>

            <div className={style.obe_card_cnt2}>
              <div className={style.obe_card}>
                <h1>Customization</h1>
                <p>
                  Adapt our software to fit the specific requirements of your
                  institution. Enjoy a seamless integration that aligns with
                  your existing processes.
                </p>
              </div>
            </div>
            <div className={style.obe_card_cnt2}>
              <div className={style.obe_card}>
                <h1>Scalability</h1>
                <p>
                  Grow with confidence. Our software is scalable to meet the
                  evolving needs of educational institutions, whether you're a
                  school, college, or university.
                </p>
              </div>
            </div>
            <div className={style.obe_card_cnt2}>
              <div className={style.obe_card}>
                <h1>Dedicated Support</h1>
                <p>
                  Rely on our expert support team to guide you through
                  implementation and address any queries promptly, ensuring a
                  smooth and successful integration. Discover the transformative
                  power of [Your Company Name]'s Exam Cell Software. Contact us
                  today for a personalized demonstration and elevate your
                  institution's examination processes to new heights of
                  efficiency and reliability.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exam;
