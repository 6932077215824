import "./App.css";
import Menubar from "./components/Navbar";
// import router from "./router";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Footer from "./components/Footer";
import Error from "./components/Error/Error";
import Erp from "./components/Erp/Erp";
import Obe from "./components/Obe/Obe";
import Exam from "./components/Exam/Exam";
import Ewallet from "./components/Ewallet/Ewallet";
import About from "./components/About/About";
import Careers from "./Careers/Careers";
import Naac from "./components/Naac/Naac";
import Nirf from "./components/Nirf/Nirf";
import Iqac from "./components/IQAC/Iqac";
import Smart from "./components/Smart/Smart";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./components/TermsConditions/TermsConditions";


function App() {
  return (
    <Router>
      <Menubar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/erp" element={<Erp />} />
        <Route path="/obe" element={<Obe />} />
        <Route path="/exam" element={<Exam />} />
        <Route path="/wallet" element={<Ewallet />} />
        <Route path="/about" element={<About />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/naac" element={<Naac />} />
        <Route path="/nirf" element={<Nirf />} />
        <Route path="/iqac" element={<Iqac />} />
        <Route path="/smart" element={<Smart />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsConditions />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
