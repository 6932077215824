import React from "react";
import style from "./Smart.module.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Lottie from "lottie-react";
import obe from "../../assets/obe.json";
import obe1 from "../../assets/smart1.jpg";
import obe2 from "../../assets/smart2.jpg";
import obe3 from "../../assets/smart3.jpg";
import obe4 from "../../assets/smart4.jpg";
const Smart = () => {
  return (
    <div>
      <div className={style.obe_bg_cnt}>
        {/* <Lottie animationData={obe} loop={true} /> */}
        <div className={style.obe_cnt}>
          <h1>Face Recognition Software</h1>
        </div>
      </div>

      <div className="container">
        <h1 className={style.obe_heading}>Key Features</h1>

        <div className={style.obe_card_cnt}>
          <img src={obe1} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>Real-time Attendance Tracking</h1>
            <p>
              Ensure timely and accurate attendance recording with our real-time
              tracking feature. Gain immediate insights into student attendance,
              enabling prompt intervention when needed.
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt}>
          <img src={obe2} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>Biometric Integration</h1>
            <p>
              Elevate security and precision with biometric integration options.
              Implement fingerprint or facial recognition for foolproof student
              identification.
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt}>
          <img src={obe3} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>Automated Roll Call</h1>
            <p>
              Streamline the attendance process with automated roll call
              functionality. Save valuable class time by eliminating manual
              attendance-taking procedures
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt}>
          <img src={obe4} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>Parent Notifications</h1>
            <p>
              Keep parents informed about their child's attendance with
              automated notifications. Enhance communication and transparency
              between educators and parents
            </p>
          </div>
        </div>
      </div>
      <div className="container d-md-flex flex-md-row gap-md-5 d-flex flex-column justify-content-center">
        <div className="row">
          <h1 className={style.obe_heading}>Benefits</h1>
          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Enhanced Classroom Efficiency</h1>
              <p>
                Optimize class time by automating attendance procedures. Enable
                educators to focus on teaching rather than administrative tasks.
              </p>
            </div>
          </div>
          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Improved Student Engagement</h1>
              <p>
                Foster a culture of accountability and attendance awareness
                among students. Promptly address attendance issues to enhance
                overall student success.
              </p>
            </div>
          </div>
          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Communication and Transparency</h1>
              <p>
                Strengthen the partnership between educators and parents through
                automated attendance notifications. Keep parents informed about
                their child's attendance patterns.
              </p>
            </div>
          </div>
          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Data-Driven Insights</h1>
              <p>
                Leverage comprehensive attendance data for informed
                decision-making. Identify trends, address potential issues, and
                enhance overall institutional effectiveness.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <h1 className={style.obe_heading}>
            How to Implement Our Smart Attendance Software
          </h1>

          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Contact Us</h1>
              <p>
                Reach out to our team to discuss your institution's specific
                requirements.
              </p>
            </div>
          </div>

          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Customization</h1>
              <p>
                We'll tailor the software to align with your unique attendance
                policies and academic workflows.
              </p>
            </div>
          </div>
          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Implementation</h1>
              <p>
                Seamlessly integrate the software into your existing educational
                systems with our expert support
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Smart;
