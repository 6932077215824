import React from "react";
import style from "./Iqac.module.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Lottie from "lottie-react";
import obe from "../../assets/obe.json";
import obe1 from "../../assets/iqac1.jpg";
import obe2 from "../../assets/iqac2.jpg";
import obe3 from "../../assets/obe1.3.jpg";
import obe4 from "../../assets/iqac4.jpg";
const Iqac = () => {
  return (
    <div>
      <div className={style.obe_bg_cnt}>
        {/* <Lottie animationData={obe} loop={true} /> */}
        <div className={style.obe_cnt}>
          <h1>
            {/* Outcome Based<h1 className="d-md-none d-sm-block">Education</h1> */}
          </h1>
        </div>
      </div>
      <div className="container">
        <h1 className={style.obe_heading}>Key Functions of IQAC</h1>

        <div className={style.obe_card_cnt}>
          <img src={obe1} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>Strategic Planning</h1>
            <p>
              Develop and execute strategic plans for institutional improvement
              in line with the institution's vision and mission.
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt}>
          <img src={obe2} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>Quality Benchmarking</h1>
            <p>
              Establish benchmarks for quality parameters and monitor the
              implementation of quality enhancement initiatives
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt}>
          <img src={obe3} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>Continuous Monitoring and Evaluation</h1>
            <p>
              Regularly monitor and evaluate the effectiveness of academic and
              administrative processes to ensure continuous improvement.
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt}>
          <img src={obe4} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>Feedback Mechanism</h1>
            <p>
              Facilitate a robust feedback mechanism involving all stakeholders,
              including students, faculty, and staff, to gather insights for
              improvement.
            </p>
          </div>
        </div>
      </div>

      <div className="container d-flex flex-column  d-md-flex flex-md-row justify-content-md-flex-start gap-5 ">
        <div className="row">
          <h1 className={style.obe_heading}>Benefits</h1>
          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Quality Enhancement</h1>
              <p>
                IQAC drives initiatives that lead to the enhancement of academic
                and administrative quality.
              </p>
            </div>
          </div>
          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Continuous Improvement</h1>
              <p>
                The cell fosters a culture of continuous improvement by
                assessing and addressing challenges in real-time
              </p>
            </div>
          </div>
          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Stakeholder Engagement</h1>
              <p>
                IQAC ensures active engagement with all stakeholders, creating a
                collaborative environment for quality enhancement.
              </p>
            </div>
          </div>
          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Accreditation Success</h1>
              <p>
                With IQAC's support, Aspiron Technologies is better positioned
                for successful accreditation processes.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <h1 className={style.obe_heading}>
            How IQAC Works at Aspiron Technologies
          </h1>

          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Establishment of Committees</h1>
              <p>
                IQAC establishes various committees to focus on specific areas
                of improvement, fostering a collaborative approach
              </p>
            </div>
          </div>

          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Data-Driven Decision-Making</h1>
              <p>
                The cell emphasizes data-driven decision-making, utilizing
                analytics for informed choices in quality enhancement
                initiatives.
              </p>
            </div>
          </div>
          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Training and Development</h1>
              <p>
                IQAC invests in the training and development of faculty and
                staff to ensure they are equipped with the latest knowledge and
                skills.
              </p>
            </div>
          </div>
          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Transparent Communication</h1>
              <p>
                The cell maintains transparency through regular communication,
                ensuring all stakeholders are well-informed about quality
                enhancement initiatives.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Iqac;
