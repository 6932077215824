import React, { useEffect, useRef, useState } from "react";
import style from "./Home.module.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import erp from "../../assets/erp.jpg";
import obe from "../../assets/obe_bg.jpg";
import naac from "../../assets/naac.jpg";
import iqac from "../../assets/iqac.png";
import nirf from "../../assets/nirf.jpg";
import smart from "../../assets/smart.jpg";
import anuragEng from "../../assets/CLIENTS/anuragEng.png";
import bvc from "../../assets/CLIENTS/bvc.jpg";
import bvces from "../../assets/CLIENTS/bvces.jpg";
import BVCITS from "../../assets/CLIENTS/BVCITS.jpg";
import ISTS from "../../assets/CLIENTS/ISTS.jpg";
import methodist from "../../assets/CLIENTS/methodist.jpg";
import mittapalli from "../../assets/CLIENTS/mittapalli.jpg";
import PSCMR from "../../assets/CLIENTS/PSCMR.png";
import RAMIREDDY from "../../assets/CLIENTS/RAMIREDDY.jpg";
import RVIT from "../../assets/CLIENTS/RVIT.jpg";
import samskruthiPharma from "../../assets/CLIENTS/samskruthiPharma.jpg";
import samskruthiPolytech from "../../assets/CLIENTS/samskruthiPolytech.PNG";
import SamskrutiEC from "../../assets/CLIENTS/SamskrutiEC.jpg";
import SRKIT from "../../assets/CLIENTS/SRKIT.png";
import swarnandhra from "../../assets/CLIENTS/swarnandhra.jpeg";
import vignan from "../../assets/CLIENTS/vignan.png";
import feedback from "../../assets/feedback.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import testimonial1 from "../../assets/testimonial1.png";

// cards from joy UI
import AspectRatio from "@mui/joy/AspectRatio";
import Link from "@mui/joy/Link";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Chip from "@mui/joy/Chip";
import Typography from "@mui/joy/Typography";

import card1 from "../../assets/f1.jpg";
import card2 from "../../assets/f2.jpg";
import card3 from "../../assets/f3.jpg";
import card4 from "../../assets/anime4.jpg";

// testimonials

import bvcTesti from "../../assets/Testimonials/bvc.png";
import samskrutiTesti from "../../assets/Testimonials/samskruthi.png";
import rvitTesti from "../../assets/Testimonials/rvit.png";
import istsTesti from "../../assets/Testimonials/ISTS.png";
import swarnaTesti from "../../assets/Testimonials/swarnaandhra.png";
import vignanTesti from "../../assets/Testimonials/vignan.png";

const Home = () => {
  var settings = {
    dots: true,
    infinite: true,
    // slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    AOS.init();
  }, []);

  let testimonials = [
    {
      img: bvcTesti,
    },
    {
      img: samskrutiTesti,
    },
    {
      img: rvitTesti,
    },
    {
      img: istsTesti,
    },
    {
      img: swarnaTesti,
    },
    {
      img: vignanTesti,
    },
  ];
  return (
    <div style={{ width: "100%" }}>
      <div className={`${style.banner_cnt}`}></div>

      <div className={style.banner_cnt2}>
        <div className={style.banner_heading_cnt}>
          <h1>Comprehensive Digital Network</h1>
          <p>for each educational institution across India.</p>
        </div>
        <p className={style.banner_desc}>
          an innovative and powerful platform redefining the landscape of higher
          education institution management. This state-of-the-art digital campus
          automation system is meticulously crafted to streamline and elevate
          the management and operational processes within your academic
          institution, paving the way for a more efficient and effective
          approach to administration.
        </p>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className={style.heading}>
              Fostering Enhanced Productivity and Operational Streamlining
            </h1>
            <p className={style.desc}>
              Experience the transformative capabilities of Aspiron as it
              streamlines tasks, amplifies study sessions, eradicates errors,
              and advances work, communication, and collaboration. Aspiron is
              your gateway to unlocking unparalleled efficiency and fostering a
              culture of excellence within the realm of education.
            </p>
          </div>
        </div>
        <div className="row">
          <div
            className="col-12 col-md-6 mb-3"
            data-aos="zoom-in"
            data-aos-offset="100"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <Card
              variant="outlined"
              orientation="horizontal"
              sx={{
                // width: 320,
                "&:hover": {
                  boxShadow: "md",
                  borderColor: "neutral.outlinedHoverBorder",
                },
              }}
            >
              <AspectRatio ratio="1" sx={{ width: 130 }}>
                <img src={card1} loading="lazy" alt="" />
              </AspectRatio>
              <CardContent>
                <Typography level="title-lg" id="card-description">
                  Digital Environment for Seamless Operations
                </Typography>
                <Typography
                  level="body-sm"
                  aria-describedby="card-description"
                  mb={1}
                >
                  <Link
                    overlay
                    underline="none"
                    href="#"
                    sx={{ color: "text.tertiary" }}
                  >
                    At the core of Aspiron lies the creation of a unified
                    digital ecosystem, harmonizing processes and the student
                    lifecycle through interconnectedness that seamlessly
                    integrates every facet of academic management
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div
            className="col-12 col-md-6 mb-3"
            data-aos-delay="100"
            data-aos="zoom-in"
            data-aos-offset="100"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <Card
              variant="outlined"
              orientation="horizontal"
              sx={{
                // width: 320,
                "&:hover": {
                  boxShadow: "md",
                  borderColor: "neutral.outlinedHoverBorder",
                },
              }}
            >
              <AspectRatio ratio="1" sx={{ width: 130 }}>
                <img src={card2} loading="lazy" alt="" />
              </AspectRatio>
              <CardContent>
                <Typography level="title-lg" id="card-description">
                  Augmented Data Protection Measures
                </Typography>
                <Typography
                  level="body-sm"
                  aria-describedby="card-description"
                  mb={1}
                >
                  <Link
                    overlay
                    underline="none"
                    href="#"
                    sx={{ color: "text.tertiary" }}
                  >
                    Aspiron implements formidable data security protocols aimed
                    at safeguarding sensitive information from unauthorized
                    access, breaches, and cyber vulnerabilities.
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="row mt-3">
          <div
            className="col-12 col-md-6 mb-3"
            data-aos-delay="200"
            data-aos="zoom-in"
            data-aos-offset="100"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <Card
              variant="outlined"
              orientation="horizontal"
              sx={{
                // width: 320,
                "&:hover": {
                  boxShadow: "md",
                  borderColor: "neutral.outlinedHoverBorder",
                },
              }}
            >
              <AspectRatio ratio="1" sx={{ width: 130 }}>
                <img src={card3} loading="lazy" alt="" />
              </AspectRatio>
              <CardContent>
                <Typography level="title-lg" id="card-description">
                  User-Friendly Interface
                </Typography>
                <Typography
                  level="body-sm"
                  aria-describedby="card-description"
                  mb={1}
                >
                  <Link
                    overlay
                    underline="none"
                    href="#"
                    sx={{ color: "text.tertiary" }}
                  >
                    Aspiron guarantees an effortless and user-friendly
                    experience, reducing complexity while optimizing
                    productivity throughout the entire user journey.
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div
            className="col-12 col-md-6 mb-3"
            data-aos-delay="300"
            data-aos="zoom-in"
            data-aos-offset="100"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <Card
              variant="outlined"
              orientation="horizontal"
              sx={{
                // width: 320,
                "&:hover": {
                  boxShadow: "md",
                  borderColor: "neutral.outlinedHoverBorder",
                },
              }}
            >
              <AspectRatio ratio="1" sx={{ width: 130 }}>
                <img src={card4} loading="lazy" alt="" />
              </AspectRatio>
              <CardContent>
                <Typography level="title-lg" id="card-description">
                  AI-Powered Conversational Interface
                </Typography>
                <Typography
                  level="body-sm"
                  aria-describedby="card-description"
                  mb={1}
                >
                  <Link
                    overlay
                    underline="none"
                    href="#"
                    sx={{ color: "text.tertiary" }}
                  >
                    Harnessing the power of state-of-the-art natural language
                    processing, Aspiron sets new standards in user engagement
                    and satisfaction, powered by the advanced capabilities of
                    ChatGPT.
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 mb-3">
            <h1 className={style.heading}>
              Platforms for Streamlined Campus Operations and Administration
            </h1>
            <p className={style.desc}>
              Aspiron caters to the specific needs of higher education
              institutions in India, offering a user-centric platform.
              Harnessing the capabilities of automation and cutting-edge
              technology, our platform empowers you to focus on enhancing
              student learning outcomes and prop elling institutional
              achievements. Embrace the transformative potential of Aspiron
              today to streamline and simplify your educational endeavors.
            </p>
          </div>
          <div
            className="col-12 mb-3"
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="800"
          >
            <Card
              variant="outlined"
              orientation="horizontal"
              sx={{
                width: "100%",
                "&:hover": {
                  boxShadow: "md",
                  borderColor: "neutral.outlinedHoverBorder",
                },
              }}
            >
              <AspectRatio
                className="d-none d-md-block"
                ratio="1"
                sx={{ width: 250 }}
              >
                <img src={erp} loading="lazy" alt="" />
              </AspectRatio>
              <CardContent className={style.card_content}>
                <Typography
                  className={style.heading_card}
                  level="title-lg"
                  id="card-description"
                >
                  ENTERPRISE RESOURCE PLANNING
                </Typography>
                <Typography
                  level="body-sm"
                  aria-describedby="card-description"
                  mb={1}
                  className={style.card_desc}
                >
                  <Link
                    overlay
                    underline="none"
                    href="#"
                    sx={{ color: "text.tertiary" }}
                  >
                    Welcome to the future of academic management with Aspiron's
                    Enterprise Resource Planning (ERP) software designed
                    exclusively for educational institutions. Our comprehensive
                    ERP solution is engineered to revolutionize administrative
                    processes, enhance collaboration, and elevate the overall
                    academic experience
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div
            className="col-12 mb-3"
            data-aos-delay="100"
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="800"
          >
            <Card
              variant="outlined"
              orientation="horizontal"
              sx={{
                width: "100%",
                "&:hover": {
                  boxShadow: "md",
                  borderColor: "neutral.outlinedHoverBorder",
                },
              }}
            >
              <AspectRatio
                ratio="1"
                className="d-none d-md-block"
                sx={{ width: 250 }}
              >
                <img src={obe} loading="lazy" alt="" />
              </AspectRatio>
              <CardContent className={style.card_content}>
                <Typography
                  level="title-lg"
                  id="card-description"
                  className={style.heading_card}
                >
                  OUTCOME-BASED EDUCATION
                </Typography>
                <Typography
                  level="body-sm"
                  aria-describedby="card-description"
                  mb={1}
                >
                  <Link
                    overlay
                    underline="none"
                    href="#"
                    sx={{ color: "text.tertiary" }}
                    className={style.card_desc}
                  >
                    Welcome to a paradigm shift in education with Aspiron's
                    Outcome-Based Education (OBE) software, a revolutionary
                    solution designed to transform the way educational
                    institutions approach curriculum design, assessment, and
                    overall student learning outcomes.
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div
            className="col-12 mb-3"
            data-aos-delay="120"
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="800"
          >
            <Card
              variant="outlined"
              orientation="horizontal"
              sx={{
                width: "100%",
                "&:hover": {
                  boxShadow: "md",
                  borderColor: "neutral.outlinedHoverBorder",
                },
              }}
            >
              <AspectRatio
                className="d-none d-md-block"
                ratio="1"
                sx={{ width: 250 }}
              >
                <img src={naac} loading="lazy" alt="" />
              </AspectRatio>
              <CardContent className={style.card_content}>
                <Typography
                  level="title-lg"
                  id="card-description"
                  className={style.heading_card}
                >
                  NATIONAL ASSESSMENT AND ACCREDITATION COUNCIL
                </Typography>
                <Typography
                  level="body-sm"
                  aria-describedby="card-description"
                  mb={1}
                  className={style.card_desc}
                >
                  <Link
                    overlay
                    underline="none"
                    href="#"
                    sx={{ color: "text.tertiary" }}
                  >
                    Aspiron Technologies, your partner in achieving and
                    sustaining excellence in education. We understand the
                    significance of accreditation in the academic realm, and we
                    are committed to supporting educational institutions like
                    yours in the NAAC (National Assessment and Accreditation
                    Council) accreditation process.
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div
            className="col-12 mb-3"
            data-aos-delay="140"
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="800"
          >
            <Card
              variant="outlined"
              orientation="horizontal"
              sx={{
                width: "100%",
                "&:hover": {
                  boxShadow: "md",
                  borderColor: "neutral.outlinedHoverBorder",
                },
              }}
            >
              <AspectRatio
                className="d-none d-md-block"
                ratio="1"
                sx={{ width: 250 }}
              >
                <img src={nirf} loading="lazy" alt="" />
              </AspectRatio>
              <CardContent className={style.card_content}>
                <Typography
                  level="title-lg"
                  id="card-description"
                  className={style.heading_card}
                >
                  NATIONAL INSTITUTIONAL RANKING FRAMEWORK
                </Typography>
                <Typography
                  level="body-sm"
                  aria-describedby="card-description"
                  mb={1}
                  className={style.card_desc}
                >
                  <Link
                    overlay
                    underline="none"
                    href="#"
                    sx={{ color: "text.tertiary" }}
                  >
                    At Aspiron Technologies, we understand the pivotal role of
                    recognition in the educational landscape. We are dedicated
                    to guiding and supporting educational institutions like
                    yours through the National Institutional Ranking Framework
                    (NIRF), India's premier framework for assessing and ranking
                    higher education institutions
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div
            className="col-12 mb-3"
            data-aos-delay="160"
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="800"
          >
            <Card
              variant="outlined"
              orientation="horizontal"
              sx={{
                width: "100%",
                "&:hover": {
                  boxShadow: "md",
                  borderColor: "neutral.outlinedHoverBorder",
                },
              }}
            >
              <AspectRatio
                className="d-none d-md-block"
                ratio="1"
                sx={{ width: 250 }}
              >
                <img src={iqac} loading="lazy" alt="" />
              </AspectRatio>
              <CardContent className={style.card_content}>
                <Typography
                  level="title-lg"
                  id="card-description"
                  className={style.heading_card}
                >
                  INTERNAL QUALITY ASSURANCE CELL
                </Typography>
                <Typography
                  level="body-sm"
                  aria-describedby="card-description"
                  mb={1}
                  className={style.card_desc}
                >
                  <Link
                    overlay
                    underline="none"
                    href="#"
                    sx={{ color: "text.tertiary" }}
                  >
                    The Internal Quality Assurance Cell (IQAC) is a systematic
                    and structured mechanism designed to channelize efforts and
                    measures to improve the overall performance of the
                    institution. At Aspiron Technologiess, the IQAC serves as
                    the driving force for quality enhancement and sustenance
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div
            className="col-12 mb-3"
            data-aos-delay="180"
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="800"
          >
            <Card
              variant="outlined"
              orientation="horizontal"
              sx={{
                width: "100%",
                "&:hover": {
                  boxShadow: "md",
                  borderColor: "neutral.outlinedHoverBorder",
                },
              }}
            >
              <AspectRatio
                className="d-none d-md-block"
                ratio="1"
                sx={{ width: 250 }}
              >
                <img src={smart} loading="lazy" alt="" />
              </AspectRatio>
              <CardContent className={style.card_content}>
                <Typography
                  level="title-lg"
                  id="card-description"
                  className={style.heading_card}
                >
                  SMART ATTENDANCE SOFTWARE
                </Typography>
                <Typography
                  level="body-sm"
                  aria-describedby="card-description"
                  mb={1}
                  className={style.card_desc}
                >
                  <Link
                    overlay
                    underline="none"
                    href="#"
                    sx={{ color: "text.tertiary" }}
                  >
                    At Aspiron Technolgies, we recognize the pivotal role that
                    efficient attendance tracking plays in the educational
                    landscape. Introducing our innovative Smart Attendance
                    Software, meticulously designed to cater to the unique needs
                    of educational institutions, fostering seamless classroom
                    management and student engagement.
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>

      <div className={style.testi_cnt}>
        <Slider {...settings}>
          {/* <div className={style.testi_div}>
            <img src={testimonial1} className={style.testi_img} alt="campus" />
          </div>
          <div>
            <img src={testimonial1} className={style.testi_img} alt="campus" />
          </div>
          <div>
            <img src={testimonial1} className={style.testi_img} alt="campus" />
          </div> */}
          {testimonials.map((item) => {
            return (
              <div>
                <img src={item.img} className={style.testi_img} alt="campus" />
              </div>
            );
          })}
        </Slider>
      </div>

      <div className="d-md-none d-block">
        <div
          className="container-fluid p-0"
          style={{ marginTop: "1%", textAlign: "left" }}
        >
          <section
            id="testimonials"
            style={{
              backgroundColor: "rgb(253 88 37 / 10%)",
              padding: "10px",
              margin: "0px",
            }}
          >
            <header className="section-header text-center">
              <h2 style={{ color: "rgb(0, 212, 199)", fontSize: "13px" }}>
                Testimonials
              </h2>
              <p style={{ fontSize: "25px", color: "#02334d" }}>
                <strong>What our Clients are saying</strong>
              </p>
            </header>
            <div
              id="testimonial-carousel"
              className="carouselwidth carousel slide mx-auto"
              data-bs-ride="carousel"
              data-bs-interval="3800"
              style={{ maxWidth: "900px" }}
            >
              <div className={style.slider_cnt}>
                <Slider {...settings}>
                  <div>
                    <div
                      className="card p-4 pb-3 d-flex justify-content-center"
                      style={{ borderRadius: "20px" }}
                    >
                      <div className="card-body">
                        <p
                          className="card-text pb-5"
                          style={{
                            borderBottom:
                              "0.7px solid rgba(228, 226, 226, 0.824)",
                          }}
                        >
                          “Exceptional results! Aspiron transformed our
                          operations with innovative software, creating a
                          unified platform for seamless management. Their
                          ongoing support ensures our institution stays ahead in
                          education technology.”
                        </p>
                        <div className="row d-flex align-items-center">
                          <div className="col-md-5 mt-3">
                            <h5>Sri Bonam Krishna Satish</h5>
                            <p>CEO</p>
                          </div>
                          <div className="col-md-5 d-flex justify-content-end"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      className="card p-4 pb-3 d-flex justify-content-center"
                      style={{ borderRadius: "20px" }}
                    >
                      <div className="card-body">
                        <p
                          className="card-text pb-5"
                          style={{
                            borderBottom:
                              "0.7px solid rgba(228, 226, 226, 0.824)",
                          }}
                        >
                          “Choosing Aspiron was the best decision for our
                          college. Their tailored solutions brought unity to our
                          diverse systems, creating a cohesive platform. The
                          ongoing support ensures a worry-free experience.
                          Thumbs up!”
                        </p>
                        <div className="row d-flex align-items-center">
                          <div className="col-md-5 mt-3">
                            <h5>Dr. C. Udaya Kiran, M.E (DU), Ph.D (OU)</h5>
                            <p>Principal</p>
                          </div>
                          <div className="col-md-5 d-flex justify-content-end"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="card p-md-4 pb-3 d-flex justify-content-center"
                      style={{ borderRadius: "20px" }}
                    >
                      <div className="card-body">
                        <p
                          className="card-text pb-5"
                          style={{
                            borderBottom:
                              "0.7px solid rgba(228, 226, 226, 0.824)",
                          }}
                        >
                          “We are beyond impressed with Aspiron’s educational
                          solutions. The software's adaptability to our unique
                          needs and their ongoing commitment to excellence have
                          elevated our institution's efficiency and innovation.”
                        </p>
                        <div className="row d-flex align-items-center">
                          <div className="col-md-5 mt-3">
                            <h5>Dr. R V Krishnaiah</h5>
                            <p>Chairman</p>
                          </div>
                          <div className="col-md-5 d-flex justify-content-end"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="card p-md-4 pb-3 d-flex justify-content-center"
                      style={{ borderRadius: "20px" }}
                    >
                      <div className="card-body">
                        <p
                          className="card-text pb-5"
                          style={{
                            borderBottom:
                              "0.7px solid rgba(228, 226, 226, 0.824)",
                          }}
                        >
                          “Incredible support! Aspiron not only provided
                          top-notch software for our institution but their
                          responsive support team ensured a smooth transition
                          and addressed our queries promptly. A truly reliable
                          partner.”
                        </p>
                        <div className="row d-flex align-items-center">
                          <div className="col-md-5 mt-3">
                            <h5>K. Upender Reddy</h5>
                            <p>Secretary</p>
                          </div>
                          <div className="col-md-5 d-flex justify-content-end"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="card p-md-4 pb-3 d-flex justify-content-center"
                      style={{ borderRadius: "20px" }}
                    >
                      <div className="card-body">
                        <p
                          className="card-text pb-5"
                          style={{
                            borderBottom:
                              "0.7px solid rgba(228, 226, 226, 0.824)",
                          }}
                        >
                          “Outstanding commitment! Aspiron understands the
                          unique needs of educational institutions, offering
                          comprehensive software solutions that consolidate
                          processes. Their responsive support ensures a smooth
                          experience. Highly recommended!”
                        </p>
                        <div className="row d-flex align-items-center">
                          <div className="col-md-5 mt-3">
                            <h5>Dr. A Gopichand M.Tech, Ph.D</h5>
                            <p>Vice - Principal</p>
                          </div>
                          <div className="col-md-5 d-flex justify-content-end"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="card p-md-4 pb-3 d-flex justify-content-center"
                      style={{ borderRadius: "20px" }}
                    >
                      <div className="card-body">
                        <p
                          className="card-text pb-5"
                          style={{
                            borderBottom:
                              "0.7px solid rgba(228, 226, 226, 0.824)",
                          }}
                        >
                          “Efficiency redefined! Aspiron has transformed our
                          school's operations. Their comprehensive software
                          suite has not only simplified tasks but also improved
                          communication, making teaching and learning a seamless
                          experience”
                        </p>
                        <div className="row d-flex align-items-center">
                          <div className="col-md-5 mt-3">
                            <h5>Dr.G.Durga Sukumar Ph.D(IIT-R)</h5>
                            <p>Principal</p>
                          </div>
                          <div className="col-md-5 d-flex justify-content-end"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="d-flex justify-content-center pt-3">
              <button
                type="button"
                data-bs-target="#testimonial-carousel"
                data-bs-slide="prev"
                style={{ background: "none", border: "none" }}
              ></button>
              <button
                type="button"
                data-bs-target="#testimonial-carousel"
                data-bs-slide="next"
                style={{ background: "none", border: "none" }}
              ></button>
            </div>
          </section>
        </div>
      </div>

      <div className={style.clients_container}>
        <div style={{ width: "100%", padding: "10px 10px", marginTop: "35px" }}>
          <div>
            <div>
              <h1 className={style.heading}>Our Clients</h1>
            </div>
          </div>
        </div>
        <div>
          <marquee behavior="scroll" direction="left" scrollamount="15">
            <div className={style.marquee_div}>
              <div style={{ width: "100%" }}>
                <img src={anuragEng} alt="first" className={style.c_img} />
              </div>
              <div style={{ width: "100%" }}>
                <img src={bvc} alt="second" className={style.c_img} />
              </div>
              <div style={{ width: "100%" }}>
                <img src={bvces} alt="third" className={style.c_img} />
              </div>
              <div style={{ width: "100%" }}>
                <img src={BVCITS} alt="fourth" className={style.c_img} />
              </div>
              <div style={{ width: "100%" }}>
                <img src={ISTS} alt="fourth" className={style.c_img} />
              </div>
              <div style={{ width: "100%" }}>
                <img src={methodist} alt="fourth" className={style.c_img} />
              </div>
              <div style={{ width: "100%" }}>
                <img src={mittapalli} alt="fourth" className={style.c_img} />
              </div>
              <div style={{ width: "100%" }}>
                <img src={PSCMR} alt="fourth" className={style.c_img} />
              </div>
              <div style={{ width: "100%" }}>
                <img src={RAMIREDDY} alt="fourth" className={style.c_img} />
              </div>
              <div style={{ width: "100%" }}>
                <img src={RVIT} alt="fourth" className={style.c_img} />
              </div>
              <div style={{ width: "100%" }}>
                <img
                  src={samskruthiPharma}
                  alt="fourth"
                  className={style.c_img}
                />
              </div>
              <div style={{ width: "100%" }}>
                <img
                  src={samskruthiPolytech}
                  alt="fourth"
                  className={style.c_img}
                />
              </div>
              <div style={{ width: "100%" }}>
                <img src={SamskrutiEC} alt="fourth" className={style.c_img} />
              </div>
              <div style={{ width: "100%" }}>
                <img src={SRKIT} alt="fourth" className={style.c_img} />
              </div>
              <div style={{ width: "100%" }}>
                <img src={swarnandhra} alt="fourth" className={style.c_img} />
              </div>
              <div style={{ width: "100%" }}>
                <img src={vignan} alt="fourth" className={style.c_img} />
              </div>
            </div>
          </marquee>
        </div>
      </div>
    </div>
  );
};

export default Home;
