import React from "react";
import style from "./Naac.module.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Lottie from "lottie-react";
import obe from "../../assets/obe.json";
import obe1 from "../../assets/naac1.jpg";
import obe2 from "../../assets/naac2.jpg";
import obe3 from "../../assets/obe1.3.jpg";
import obe4 from "../../assets/naac3.jpg";

const Naac = () => {
  return (
    <div>
      <div className={style.obe_bg_cnt}>
        {/* <Lottie animationData={obe} loop={true} /> */}
        <div className={style.obe_cnt}>
          {/* <h1>Outcome Based<h1 className="d-md-none d-sm-block">Education</h1></h1> */}
        </div>
      </div>
      <div className="container">
        <h1 className={style.obe_heading}>Why NAAC Accreditation Matters</h1>

        <div className={style.obe_card_cnt}>
          <img src={obe1} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>Quality Assurance</h1>
            <p>
              NAAC accreditation ensures that your institution adheres to
              rigorous quality standards in teaching, research, and overall
              governance.
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt}>
          <img src={obe2} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>Global Recognition</h1>
            <p>
              Accreditation by NAAC enhances the global standing of your
              institution, making it more attractive to students, faculty, and
              collaborators on an international scale.
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt}>
          <img src={obe3} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>Continuous Improvement</h1>
            <p>
              The accreditation process encourages institutions to engage in
              self-assessment and continuous improvement, fostering a culture of
              excellence.
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt}>
          <img src={obe4} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>Funding Opportunities</h1>
            <p>
              Many funding agencies and government bodies prioritize accredited
              institutions when allocating resources, creating additional
              opportunities for growth and development.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <h1 className={style.obe_heading}>
          How Aspiron Technologies Supports Your NAAC Journey
        </h1>
        <div className={style.obe_card_cnt2}>
          <div className={style.obe_card}>
            <h1>Consultation Services</h1>
            <p>
              Expert guidance and consultation to help your institution
              understand NAAC criteria, standards, and the accreditation
              process.
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt2}>
          <div className={style.obe_card}>
            <h1>Documentation Support</h1>
            <p>
              Assistance in the preparation of the required documentation,
              ensuring that your institution meets the necessary standards
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt2}>
          <div className={style.obe_card}>
            <h1>Training Programs</h1>
            <p>
              Specialized training programs for faculty and staff to align with
              NAAC guidelines and promote a culture of quality enhancement
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt2}>
          <div className={style.obe_card}>
            <h1>Mock Assessments</h1>
            <p>
              Conducting mock assessments to prepare your institution for the
              actual accreditation visit, identifying areas for improvement.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Naac;
