import React from "react";
import style from "./Obe.module.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Lottie from "lottie-react";
import obe from "../../assets/obe.json";
import obe1 from "../../assets/obe1.1.jpg";
import obe2 from "../../assets/obe1.2.png";
import obe3 from "../../assets/obe1.3.jpg";
import obe4 from "../../assets/obe1.4.jpg";
const Obe = () => {
  return (
    <div>
      <div className={style.obe_bg_cnt}>
        {/* <Lottie animationData={obe} loop={true} /> */}
        <div className={style.obe_cnt}>
          <h1>
            Outcome Based<h1 className="d-md-none d-sm-block">Education</h1>
          </h1>
        </div>
      </div>

      <div className="container">
        <h1 className={style.obe_heading}>Key Features</h1>

        <div className={style.obe_card_cnt}>
          <img src={obe1} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>Curriculum Alignment</h1>
            <p>
              Align your curriculum with predefined learning outcomes. Our OBE
              software ensures that every element of your academic program
              contributes directly to the mastery of essential skills and
              knowledge.
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt}>
          <img src={obe2} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>Learning Outcome Mapping</h1>
            <p>
              Map learning outcomes to individual courses and assessments.
              Visualize the progression of student skills and knowledge
              throughout their academic journey, providing valuable insights for
              continuous improvement.
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt}>
          <img src={obe3} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>Analytics and Reporting</h1>
            <p>
              Access powerful analytics and reporting tools. Gain actionable
              insights into student performance, curriculum effectiveness, and
              areas for improvement. Make informed decisions to enhance the
              overall quality of education.
            </p>
          </div>
        </div>
        <div className={style.obe_card_cnt}>
          <img src={obe4} alt="Exam" className={style.obe_card_img} />
          <div className={style.obe_card}>
            <h1>Student-Centric Approach</h1>
            <p>
              Foster a student-centric approach to education. Our OBE software
              empowers students to take charge of their learning journey,
              providing them with a clear understanding of the skills and
              knowledge they are expected to acquire.
            </p>
          </div>
        </div>
      </div>
      <div className="container d-flex flex-column d-md-flex flex-md-row justify-content-start">
        <div>
          <h1 className={style.obe_heading}>Benefits</h1>
          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Enhanced Academic Quality</h1>
              <p>
                Elevate the quality of education with a focus on clearly defined
                learning outcomes. Our OBE software ensures that academic
                programs are aligned with industry standards and expectations.
              </p>
            </div>
          </div>
          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Continuous Improvement</h1>
              <p>
                Enable continuous improvement through data-driven insights.
                Identify areas for enhancement in curriculum design, teaching
                methods, and assessment strategies, fostering a culture of
                ongoing refinement.
              </p>
            </div>
          </div>
          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Accreditation Readiness</h1>
              <p>
                Prepare for accreditation processes with confidence. Our OBE
                software assists institutions in demonstrating compliance with
                accreditation standards, ensuring a smooth and successful
                accreditation journey.
              </p>
            </div>
          </div>
          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Accreditation Readiness</h1>
              <p>
                Prepare for accreditation processes with confidence. Our OBE
                software assists institutions in demonstrating compliance with
                accreditation standards, ensuring a smooth and successful
                accreditation journey.
              </p>
            </div>
          </div>
        </div>
        <div>
          <h1 className={style.obe_heading}>Why Aspiron's OBE Software?</h1>

          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Educational Expertise</h1>
              <p>
                PrBenefit from our deep understanding of educational processes.
                Our software is developed with the unique needs of educational
                institutions in mind.
              </p>
            </div>
          </div>

          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Adaptability</h1>
              <p>
                Customize our OBE software to fit the specific requirements of
                your institution. Ensure seamless integration with your existing
                educational framework.
              </p>
            </div>
          </div>
          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Scalability</h1>
              <p>
                Grow confidently with our scalable solution. Whether you're a
                school, college, or university, our OBE software evolves with
                the changing needs of educational institutions.
              </p>
            </div>
          </div>
          <div className={style.obe_card_cnt2}>
            <div className={style.obe_card}>
              <h1>Dedicated Support</h1>
              <p>
                Rely on our expert support team to guide you through
                implementation and address any queries promptly, ensuring a
                smooth transition to OBE practices. Discover the transformative
                power of [Your Company Name]'s Outcome-Based Education software.
                Contact us today for a personalized demonstration and embark on
                a journey toward a more outcomes-focused, student-centric, and
                quality-driven education system.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Obe;
